import request from '@/utils/request'

const userApi = {
  partList: '/api/module_total',

}


 // 部件-部件列表  ApiType
export function reqPartList(parameter) {
  return request({
    url: userApi.partList,
    method: 'post',
    data: parameter
  })
}



